const ServiceCard = ({ icon: Icon, text }) => {
  return (
    <div className="group relative cursor-pointer overflow-hidden bg-[#606C38] px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:max-w-sm sm:rounded-lg sm:px-10 text-[#FEFAE0] ">
      <span className="absolute top-10 z-0 h-20 w-20 rounded-full bg-[#BC6C25] transition-all duration-300 group-hover:scale-[10]"></span>
      <div className="relative z-10 mx-auto max-w-md">
        <span className="grid h-20 w-20 place-items-center rounded-full bg-[#BC6C25] transition-all duration-300 group-hover:bg-[#DDA15E] ">
          <Icon className="w-6 h-6 group-hover:text-[#283618]"/>
        </span>
        <div className="font-quicksand space-y-6 pt-5  leading-7 text-[#FEFAE0] transition-all duration-300 group-hover:text-[#FEFAE0]">
          <p>{text}</p>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
