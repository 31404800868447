import React from "react";
import { useTranslation } from 'react-i18next';

const Methodologie = () => {
  const { t } = useTranslation();
  const steps = [
    {
      title: t('method.block.firstTitle'),
      description: t('method.block.firstDescription'),
      icon: (props) => (
        <svg
          {...props}
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          viewBox="0 0 24 24"
          strokeLinecap="round"
          strokeLinejoin="round"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M19.875 6.27a2.225 2.225 0 0 1 1.125 1.948v7.284c0 .809 -.443 1.555 -1.158 1.948l-6.75 4.27a2.269 2.269 0 0 1 -2.184 0l-6.75 -4.27a2.225 2.225 0 0 1 -1.158 -1.948v-7.285c0 -.809 .443 -1.554 1.158 -1.947l6.75 -3.98a2.33 2.33 0 0 1 2.25 0l6.75 3.98h-.033z"></path>
          <path d="M10 10l2 -2v8"></path>
        </svg>
      ),
    },
    {
      title: t('method.block.secondTitle'),
      description: t('method.block.secondDescription'),
      icon: (props) => (
        <svg
          {...props}
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          viewBox="0 0 24 24"
          strokeLinecap="round"
          strokeLinejoin="round"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M19.875 6.27a2.225 2.225 0 0 1 1.125 1.948v7.284c0 .809 -.443 1.555 -1.158 1.948l-6.75 4.27a2.269 2.269 0 0 1 -2.184 0l-6.75 -4.27a2.225 2.225 0 0 1 -1.158 -1.948v-7.285c0 -.809 .443 -1.554 1.158 -1.947l6.75 -3.98a2.33 2.33 0 0 1 2.25 0l6.75 3.98h-.033z"></path>
          <path d="M10 8h3a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 0 -1 1v2a1 1 0 0 0 1 1h3"></path>
        </svg>
      ),
    },
    {
      title: t('method.block.thirdTitle'),
      description: t('method.block.thirdDescription'),
      icon: (props) => (
        <svg
        stroke="currentColor"
        fill="none"
        strokeWidth="2"
        viewBox="0 0 24 24"
        strokeLinecap="round"
        strokeLinejoin="round"
        color="white"
        style={{ color: "white" }}
        height="50"
        width="50"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M19.875 6.27a2.225 2.225 0 0 1 1.125 1.948v7.284c0 .809 -.443 1.555 -1.158 1.948l-6.75 4.27a2.269 2.269 0 0 1 -2.184 0l-6.75 -4.27a2.225 2.225 0 0 1 -1.158 -1.948v-7.285c0 -.809 .443 -1.554 1.158 -1.947l6.75 -3.98a2.33 2.33 0 0 1 2.25 0l6.75 3.98h-.033z"></path>
        <path d="M10 9a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1"></path>
      </svg>
      ),
    },
    {
      title: t('method.block.fourthTitle'),
      description: t('method.block.fourthDescription'),
      icon: (props) => (
        <svg
        stroke="currentColor"
        fill="none"
        strokeWidth="2"
        viewBox="0 0 24 24"
        strokeLinecap="round"
        strokeLinejoin="round"
        color="white"
        style={{ color: "white" }}
        height="50"
        width="50"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M19.875 6.27a2.225 2.225 0 0 1 1.125 1.948v7.284c0 .809 -.443 1.555 -1.158 1.948l-6.75 4.27a2.269 2.269 0 0 1 -2.184 0l-6.75 -4.27a2.225 2.225 0 0 1 -1.158 -1.948v-7.285c0 -.809 .443 -1.554 1.158 -1.947l6.75 -3.98a2.33 2.33 0 0 1 2.25 0l6.75 3.98h-.033z"></path>
        <path d="M10 8v3a1 1 0 0 0 1 1h3"></path>
        <path d="M14 8v8"></path>
      </svg>
      
      ),
    },
  ];
  return (
    <div className="bg-[#FEFAE0] px-4 py-10 min-h-screen" id="method">
      <div className="max-w-7xl mx-auto h-max px-6 md:px-12 xl:px-6">
        <div className="px-8 lg:mx-32 flex flex-col justify-center items-center">
        
          <h2 className="text-2xl font-raleway font-bold my-8 text-[#283618] md:text-4xl lg:text-5xl xl:text-6xl">{t('method.title')}</h2>
          <p className="font-quicksand text-[#283618] text-lg">
          {t('method.description')}
          </p>
        </div>
        <div className="mt-16 grid divide-x divide-y divide-[#283618] overflow-hidden rounded-3xl border text-[#FEFAE0] border-[#283618] sm:grid-cols-2 lg:grid-cols-4 lg:divide-y-0 xl:grid-cols-4">
          {steps.map((step, index) => (
            <div
              key={index}
              className="group relative bg-[#606C38] transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10"
            >
              <div className="relative space-y-8 py-12 p-8">
                <step.icon className="text-white" height={50} width={50} />
                <div className="space-y-2">
                  <h5 className="text-xl font-raleway font-bold text-[#FEFAE0] transition">
                    {step.title}
                  </h5>
                  <p className="font-quicksand text-[#FEFAE0]">{step.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};



export default Methodologie;
