import React from 'react';
import Navbar from '../components/Navbar';

const Header = () => {
  return (
    <div>
<Navbar/>
    </div>
  )
}

export default Header