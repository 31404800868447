import React from "react";
import { useTranslation } from 'react-i18next';

const ProjectCard = ({ image, title, link, description }) => {
  const { t } = useTranslation();

  return (
    <div className="group w-64 h-64 [perspective:1000px] rounded-lg cursor-pointer">
      {/* Container for flipping */}
      <div className="relative w-full h-full transition-transform duration-700 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)]">
        {/* Front side */}
        <div className="absolute w-full h-full [backface-visibility:hidden]">
        <img src={image} alt={title} className="w-full h-full object-cover rounded-lg" />
        </div>
        {/* Back side */}
        <div className="absolute w-full h-full bg-[#FEFAE0]  flex items-center justify-center flex-col space-y-4 [backface-visibility:hidden] [transform:rotateY(180deg)] rounded-lg">
            <button
            aria-label={t('aria.demo')}
             className="font-raleway shadow bg-[#BC6C25] hover:bg-[#DDA15E] text-[#FEFAE0] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:text-[#283618]">
           <a href={link} target="_blank"> {t('projects.demo')}</a> 
          </button>
          <p className="text-[#606C38] font-raleway text-center font-semibold">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
