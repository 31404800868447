import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const { t } = useTranslation();

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div id="faq" className="relative isolate overflow-hidden bg-[#FEFAE0]">
      <div className="py-24 px-8 max-w-5xl mx-auto flex flex-col md:flex-row gap-12">
        {/* FAQ Heading */}
        <div className="flex flex-col text-left basis-1/2 font-raleway">
          <p className="inline-block font-semibold text-[#283618] mb-4">{t('faq.title')}</p>
          <p className="sm:text-4xl text-3xl font-extrabold text-[#283618]">{t('faq.subtitle')}</p>
        </div>

        {/* FAQ Items */}
        <ul className="basis-1/2 font-quicksand">
          {t('faq.items', { returnObjects: true }).map((item, index) => (
            <li key={index} className="group">
              <button
                onClick={() => toggleFAQ(index)}
                className="relative flex gap-2 items-center w-full py-5 text-base font-semibold text-left border-t md:text-lg border-[#283618]/10"
                aria-expanded={openIndex === index}
              >
                <span className="flex-1 text-[#283618]">{item.question}</span>
                <svg
                  className={`flex-shrink-0 w-4 h-4 ml-auto fill-current text-[#283618] transition-transform duration-200 ease-out ${
                    openIndex === index ? 'rotate-180' : ''
                  }`}
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect y="7" width="16" height="2" rx="1" />
                  <rect
                    y="7"
                    width="16"
                    height="2"
                    rx="1"
                    className={`block ${openIndex === index ? 'opacity-0' : ''} origin-center rotate-90 transition-opacity duration-200`}
                  />
                </svg>
              </button>
              <div
                className={`transition-all duration-300 ease-in-out overflow-hidden ${
                  openIndex === index ? 'max-h-60' : 'max-h-0'
                }`}
              >
                <div className="pb-5 leading-relaxed text-[#283618]">
                  <p className="space-y-2 leading-relaxed">
                    {item.answer}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FAQ;
