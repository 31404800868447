import React from 'react';
import { FaLinkedin, FaInstagram } from "react-icons/fa";
import { useTranslation } from 'react-i18next';
import WhatsAppButton from '../components/WhatsappButton';


const FooterBlog = () => {
  const { t } = useTranslation();
  return (
    <footer id='footer' className="flex flex-col space-y-10 justify-center  p-10 bg-[#BC6C25]">
  

      <div className="flex justify-center space-x-5">
        <a href="https://www.linkedin.com/company/mburutech/" target="_blank" rel="noopener noreferrer" className='w-10 h-10 text-[#FEFAE0]'>
          <FaLinkedin className='w-full h-full'/>
        </a>

        <a href="https://instagram.com/mburutech" target="_blank" rel="noopener noreferrer" className='w-10 h-10 text-[#FEFAE0]'>
          <FaInstagram className='w-full h-full'/>
        </a>

      </div>
      <div  className="text-center text-[#FEFAE0] ">
     
      <p className='font-quicksand'>info@mburu.tech</p> 
      <p className='font-quicksand'>(+54)3794222281</p> 
      <WhatsAppButton/>
      </div>

  
    </footer>
  );
};

export default FooterBlog;
