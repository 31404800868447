import React from "react";
import ProjectCard from "../components/ProjectCard";
import web4 from "../assets/web4.png"
import  web6 from "../assets/web6.png"
import  web5 from "../assets/web5.png"
import  web9 from "../assets/web9.png"
import { useTranslation } from 'react-i18next';

const Projects = () => {
  const { t } = useTranslation();
  const projects = [
    { id: 1, image: web5, description: t('projects.cardOne'), link:"https://probluesite.vercel.app/" },
    { id: 2, image: web6, description: t('projects.cardTwo'), link:"https://companyexample.vercel.app/" },
    { id: 3, image: web4, description: t('projects.cardThree'), link:"https://lightbluesite.vercel.app/" },
    { id: 4, image: web9, description: t('projects.cardFour'),link:"https://restoexample.vercel.app/" },
  ];

  return (
    <section className="bg-[#606C38] py-10 space-y-10 min-h-screen" id="projects">
      <div className="px-8 lg:mx-32 flex flex-col justify-center items-center">
        
        <h2 className="my-8 text-2xl font-raleway font-bold text-[#FEFAE0] md:text-4xl lg:text-5xl xl:text-6xl">{t('projects.title')}</h2>
        <p className="font-quicksand text-[#FEFAE0] text-xl">
        {t('projects.description')}
        </p>
      </div>
      <div className="grid gap-8 p-10 grid-cols-1 md:grid-cols-2 place-items-center content-center">

      {projects.map((project) => (
        <ProjectCard key={project.id} image={project.image} title={project.title} link={project.link} description={project.description}/>
      ))}
    </div>
    </section>
  );
};

export default Projects;
