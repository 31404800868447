import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import Capidos from "../assets/capidos.webp";
import LanguageToggle from '../components/Toggle';


const NavbarBlog = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const goHome = (event) => {
        event.preventDefault();
        navigate('/');
    };

    const goBack = (event) => {
        event.preventDefault();
        // Si la URL actual es /blog/responsive-design, navega a /blog
        if (location.pathname !== '/blog') {
            navigate('/blog');
        }
    };

    // Verifica si estamos en la página de blog principal
    const isBlogHome = location.pathname === '/blog';

    return (
        <section className="bg-[#FEFAE0] fixed w-full z-50">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16 md:mx-10">
                    <div className="flex items-center h-8 w-8 ml-1 md:ml-0 space-x-1">
                        <img src={Capidos} alt="imagen de carpincho" className='w-full h-full' />
                        <p className="font-raleway text-[#283618] font-bold mt-1">MburuTech</p>
                    </div>
                    <div className="font-raleway space-x-4">
                    <div className="">   <LanguageToggle /></div>
                        <a onClick={goHome} className="cursor-pointer text-[#283618] hover:text-[#DDA15E] pl-5 py-2 rounded-md text-sm font-medium">
                            {t('blog.navbar.homeButton')}
                        </a>
                        {!isBlogHome && (
                            <a onClick={goBack} className="cursor-pointer text-[#283618] hover:text-[#DDA15E]  py-2 rounded-md text-sm font-medium">
                                {t('blog.navbar.backButton')}
                            </a>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default NavbarBlog;
