import { SlBubble, SlRocket, SlPuzzle, SlTrophy } from "react-icons/sl";
import ServiceCard from "../components/ServiceCard";
import { useTranslation } from 'react-i18next';

const Services = () => {
  const { t } = useTranslation();
  const services = [
    {
      icon: SlBubble,
      text: t('services.firstText')
    },
    {
      icon: SlRocket,
      text: t('services.secondText')
    },
    {
      icon: SlPuzzle,
      text: t('services.thirdText')
    },
    {
      icon: SlTrophy,
      text: t('services.fourthText')
    }
  ];

  return (
    <div className="relative flex min-h-screen flex-col justify-center overflow-hidden bg-[#FEFAE0] py-16 px-6">
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 ">
        {services.map((service, index) => (
          <ServiceCard key={index} icon={service.icon} text={service.text} />
        ))}
      </div>
    </div>
  );
};

export default Services;
