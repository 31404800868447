import React from "react";
import { useTranslation } from "react-i18next";

const Prices = () => {
  const { t } = useTranslation();
  const phoneNumber = '+543794222281';
  const message = t('wabutton');

  // Helper function to safely handle translations
  const getArray = (key) => {
    const result = t(key, { returnObjects: true });
    return Array.isArray(result) ? result : [];
  };

  return (
    <section className="bg-[#FEFAE0] py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2
          className="text-4xl font-extrabold text-[#283618] mb-12"
          style={{ fontFamily: "Raleway, sans-serif" }}
        >
          {t("prices.title")}
        </h2>
        <div className="grid gap-12 md:grid-cols-3">
          {/* Plan Básico */}
          <div className="border border-[#DDA15E] rounded-3xl shadow-lg p-8 bg-white hover:scale-105 hover:shadow-xl transition-transform duration-300 flex flex-col justify-between">
            <div>
              <h3
                className="text-2xl font-bold text-[#283618] mb-4"
                style={{ fontFamily: "Raleway, sans-serif" }}
              >
                {t("prices.plans.basic.title")}
              </h3>
              <p
                className="text-xl text-[#606C38] font-semibold mb-8"
                style={{ fontFamily: "Quicksand, sans-serif" }}
              >
                {t("prices.plans.basic.price")}
              </p>
              <ul
                className="text-[#283618] text-left space-y-4 mb-8"
                style={{ fontFamily: "Quicksand, sans-serif" }}
              >
                {getArray("prices.plans.basic.features").map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
            <button className="bg-[#DDA15E] hover:bg-[#BC6C25] text-white font-bold py-3 px-6 rounded-lg w-full">
            <a
              href={`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`}
              target="_blank"
              rel="noopener noreferrer"

            > {t("prices.button.start")}</a>
            </button>
          </div>

          {/* Plan Profesional */}
          <div className="border border-[#DDA15E] rounded-3xl shadow-2xl p-8 bg-[#283618] text-white transform scale-105 hover:scale-110 hover:shadow-2xl transition-transform duration-300 flex flex-col justify-between">
            <div>
              <h3
                className="text-2xl font-bold mb-4"
                style={{ fontFamily: "Raleway, sans-serif" }}
              >
                {t("prices.plans.professional.title")}
              </h3>
              <p
                className="text-xl font-semibold mb-8"
                style={{ fontFamily: "Quicksand, sans-serif" }}
              >
                {t("prices.plans.professional.price")}
              </p>
              <ul
                className="text-left space-y-4 mb-8"
                style={{ fontFamily: "Quicksand, sans-serif" }}
              >
                {getArray("prices.plans.professional.features").map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
            <button className="bg-[#DDA15E] hover:bg-[#BC6C25] text-white font-bold py-3 px-6 rounded-lg w-full">
              {t("prices.button.start")}
            </button>
          </div>

          {/* Plan Premium */}
          <div className="border border-[#DDA15E] rounded-3xl shadow-lg p-8 bg-white hover:scale-105 hover:shadow-xl transition-transform duration-300 flex flex-col justify-between">
            <div>
              <h3
                className="text-2xl font-bold text-[#283618] mb-4"
                style={{ fontFamily: "Raleway, sans-serif" }}
              >
                {t("prices.plans.premium.title")}
              </h3>
              <p
                className="text-xl text-[#606C38] font-semibold mb-8"
                style={{ fontFamily: "Quicksand, sans-serif" }}
              >
                {t("prices.plans.premium.price")}
              </p>
              <div className="mb-8">
                <h4
                  className="text-lg font-semibold text-[#283618] mb-4"
                  style={{ fontFamily: "Raleway, sans-serif" }}
                >
                  {t("prices.plans.premium.examples.title")}
                </h4>
                <ul
                  className="text-[#283618] text-left space-y-2"
                  style={{ fontFamily: "Quicksand, sans-serif" }}
                >
                  {getArray("prices.plans.premium.examples.list").map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
              <div>
                <h4
                  className="text-lg font-semibold text-[#283618] mb-4"
                  style={{ fontFamily: "Raleway, sans-serif" }}
                >
                  {t("prices.plans.premium.includes.title")}
                </h4>
                <ul
                  className="text-[#283618] text-left space-y-2"
                  style={{ fontFamily: "Quicksand, sans-serif" }}
                >
                  {getArray("prices.plans.premium.includes.list").map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
            <button className="bg-[#DDA15E] hover:bg-[#BC6C25] text-white font-bold py-3 px-6 rounded-lg w-full mt-8">
              {t("prices.button.start")}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Prices;
