
import {useState, useEffect} from 'react';
import { FaArrowUp } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const ScrollToTopButton = () => {
    const [showButton, setShowButton] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
      const handleScroll = () => {
        const homeSection = document.getElementById('home');
        
        if (homeSection) {
          const homeBottom = homeSection.offsetTop + homeSection.offsetHeight;
          
          // Muestra el botón solo si el scroll ha pasado la sección 'home'
          if (window.scrollY >= homeBottom) {
            setShowButton(true);
          } else {
            setShowButton(false);
          }
        }
      };
  
      window.addEventListener('scroll', handleScroll);
      
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };

  return (
    <>
    {showButton && (
      
    <button
      
      onClick={scrollToTop}
      
      className="fixed bottom-24 right-7 p-3 bg-[#FEFAE0] text-[#283618] rounded-full shadow-lg hover:bg-[#606C38] hover:text-[#FEFAE0] transition z-[500] hover:opacity-50 md:animate-bounce"
      aria-label={t('aria.top')}
    >
      <FaArrowUp size={24} />
    </button>)}
    </>
  );
};

export default ScrollToTopButton;
