import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const BlogPostPage = () => {
  const { postId } = useParams();
  const [post, setPost] = useState(null);
  const { t, i18n } = useTranslation();

  const posts = [
    {
      id: 'responsive-design',
      title: t('blog.firstEntry.title'),
      content: [t('blog.firstEntry.firstParagraph'), t('blog.firstEntry.secondParagraph'), t('blog.firstEntry.thirdParagraph'), t('blog.firstEntry.fourthParagraph')],
      publishedAt: '17-10-2024',
      featuredImage: 'https://plus.unsplash.com/premium_vector-1727133679102-6fbd55ff5ebf?q=80&w=1480&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    {
      id: 'seo',
      title: 'SEO',
      content: 'Seo articulo de SEO',
      publishedAt: '2024-01-02',
      featuredImage: 'https://images.unsplash.com/photo-1493723843671-1d655e66ac1c',
    },
    {
      id: 'accessibility',
      title: 'ACCESIBILIDAD',
      content: 'ACCESIBILIDAD articulo de accesibilidad',
      publishedAt: '2024-01-02',
      featuredImage: 'https://images.unsplash.com/photo-1493723843671-1d655e66ac1c',
    },
  ];

  useEffect(() => {
    const foundPost = posts.find((p) => p.id === postId);
    setPost(foundPost);
  }, [postId, i18n.language]); // Añadir i18n.language como dependencia para actualizar el post

  if (!post) {
    return <p>{t('blog.loading')}</p>;
  }


  const { title, content, publishedAt, featuredImage } = post;
  const shareUrl = window.location.href;
  const phoneNumber = '+543794222281'; 
  const message = t('wabutton'); 
  return (
    <>
   
      <div className=" mx-auto px-4 sm:px-6 lg:px-8 bg-[#DDA15E] w-full">
        <div className="max-w-3xl mx-auto">
          <div className="py-8 text-[#283618]">
            <h1 className="text-3xl font-raleway font-bold pt-14 mb-2">{title}</h1>
            <p className=" font-quicksand">{t('blog.published')} {publishedAt}</p>
          </div>
          <img src={featuredImage} alt="Featured" className=" h-auto mb-8" />
          <div className="text-[#283618] font-quicksand text-left md:text-justify ">
            {Array.isArray(content) && content.map((paragraph, index) => (
              <p key={index} className="pb-8">{paragraph}</p>
            ))}
          </div>
          <button 
          aria-label={t('aria.contact')}
          className="font-quicksand mb-8 p-2 shadow bg-[#283618] hover:bg-[#606C38] text-[#FEFAE0] font-bold rounded focus:outline-none focus:shadow-outline ">
       
          <a
      href={`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`}
      target="_blank"
      rel="noopener noreferrer"
    
    >{t('hero.button')}</a>
          </button>

          {/* Botones para compartir */}
          {/* <div className="flex space-x-4 mt-8">
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`}
              target="_blank"
              rel="noopener noreferrer"
              className="px-4 py-2 bg-blue-600 text-white rounded"
            >
              Compartir en Facebook
            </a>

            <a
              href={`https://api.whatsapp.com/send?text=${encodeURIComponent(title)}%20${encodeURIComponent(shareUrl)}`}
              target="_blank"
              rel="noopener noreferrer"
              className="px-4 py-2 bg-green-500 text-white rounded"
            >
              Compartir en WhatsApp
            </a>

            <a
              href={`https://www.instagram.com/`}
              target="_blank"
              rel="noopener noreferrer"
              className="px-4 py-2 bg-pink-600 text-white rounded"
            >
              Copia el enlace para compartir en Instagram
            </a>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default BlogPostPage;
