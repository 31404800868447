import React, { useState } from 'react';
import Capidos from "../assets/capidos.webp";
import { useTranslation } from 'react-i18next';
import LanguageToggle from '../components/Toggle';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  return (
    <nav className="bg-[#FEFAE0] fixed w-full z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 md:mx-12">
          <div className="flex items-center h-8 w-8 ml-1 md:ml-0 space-x-1">
            <img src={Capidos} alt="imagen de carpincho" className='w-full h-full' />
            <p className="font-raleway text-[#283618] font-bold mt-1">MburuTech</p>
          </div>
          <div className="hidden md:block">
            {/* <div className="font-raleway flex items-baseline md:space-x-2"> */}
            <div className="font-raleway hidden md:grid md:grid-cols-3 md:py-4 lg:flex">

              <a href="#" className="text-[#283618] hover:text-[#DDA15E] px-3 py-2 rounded-md text-sm font-medium">{t('navbar.home')}</a>
              <a href="#about" className="text-[#283618] hover:text-[#DDA15E] px-3 py-2 rounded-md text-sm font-medium">{t('navbar.about')}</a>
              <a href="#faq" className="text-[#283618] hover:text-[#DDA15E] px-3 py-2 rounded-md text-sm font-medium">FAQ</a>
              <a href="#footer" className="text-[#283618] hover:text-[#DDA15E] px-3 py-2 rounded-md text-sm font-medium">{t('navbar.contact')}</a>
              <a href="/blog" className="text-[#283618] hover:text-[#DDA15E] px-3 py-2 rounded-md text-sm font-medium">Blog</a>
              <div className="absolute right-16 top-1/3 pr-2 transform -translate-y-1/2">
                <LanguageToggle />
              </div>
            </div>
          </div>
          <div className="-mr-2 flex md:hidden">
            <div className="absolute right-16 top-1/3 pr-2 transform -translate-y-1/2">
              <LanguageToggle />
            </div>
            <button
              aria-label={t('aria.navbar')}
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-[#283618] hover:text-white hover:bg-[#FEFAE0] focus:outline-none"
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}
                />

              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      {isOpen && (
        <div className="absolute top-16 left-0 w-full bg-[#FEFAE0] z-40">
          <div className="font-raleway px-2 pt-2 pb-3 space-y-1 sm:px-3">

            <a href="#" className="text-[#283618] block px-3 py-2 rounded-md text-base font-medium" onClick={handleLinkClick}>{t('navbar.home')}</a>
            <a href="#about" className="text-[#283618] block px-3 py-2 rounded-md text-base font-medium" onClick={handleLinkClick}>{t('navbar.about')}</a>
            <a href="#faq" className="text-[#283618] block px-3 py-2 rounded-md text-base font-medium" onClick={handleLinkClick}>FAQ</a>
            <a href="#footer" className="text-[#283618] block px-3 py-2 rounded-md text-base font-medium" onClick={handleLinkClick}>{t('navbar.contact')}</a>
            <a href="/blog" className="text-[#283618] block px-3 py-2 rounded-md text-base font-medium" onClick={handleLinkClick}>Blog</a>
          </div>
        </div>
      )}

      {/* Agrega el toggle de idioma aquí */}
      {/* <div className="absolute right-4 top-1/2 transform -translate-y-1/2">
        <LanguageToggle />
      </div> */}
    </nav>
  );
};

export default Navbar;
