import React from 'react'

const Pricing = () => {
  return (
    <div className='grid h-screen place-items-center'>
        {/* <div>
        <a href="https://www.mercadopago.com.ar/subscriptions/checkout?preapproval_plan_id=2c938084930506df01930d2f913c02e2" name="MP-payButton" class='blue-ar-l-rn-none'>Suscribirme</a>
<script type="text/javascript">
   (function() {
      function $MPC_load() {
         window.$MPC_loaded !== true && (function() {
         var s = document.createElement("script");
         s.type = "text/javascript";
         s.async = true;
         s.src = document.location.protocol + "//secure.mlstatic.com/mptools/render.js";
         var x = document.getElementsByTagName('script')[0];
         x.parentNode.insertBefore(s, x);
         window.$MPC_loaded = true;
      })();
   }
   window.$MPC_loaded !== true ? (window.attachEvent ? window.attachEvent('onload', $MPC_load) : window.addEventListener('load', $MPC_load, false)) : null;
   })();
  /*
        // to receive event with message when closing modal from congrants back to site
        function $MPC_message(event) {
          // onclose modal ->CALLBACK FUNCTION
         // !!!!!!!!FUNCTION_CALLBACK HERE Received message: {event.data} preapproval_id !!!!!!!!
        }
        window.$MPC_loaded !== true ? (window.addEventListener("message", $MPC_message)) : null; 
        
</script>
        </div>
        <button>SUSCRIPCION</button> */}
    </div>
  )
}

export default Pricing