// src/BlogPage.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import StormEffect from '../components/StormEffect'; // Importa el componente de tormenta

const BlogPage = () => {
  const { t } = useTranslation();
  const articles = [
    {
      id: 1,
      title: t('blog.firstEntry.title'),
      excerpt: t('blog.firstEntry.intro'),
      date: '17-10-2024',
      slug: 'responsive-design',
    },
    // {
    //   id: 2,
    //   title: 'SEO',
    //   excerpt: t('blog.firstEntry.description'),
    //   date: '2024-10-02',
    //   slug: 'seo',
    // },
    // {
    //   id: 3,
    //   title: 'Accesibilidad',
    //   excerpt: t('blog.firstEntry.description'),
    //   date: '2024-10-03',
    //   slug: 'accessibility',
    // },
  ];

  return (
    <>

      <section className="text-center text-[#FEFAE0] bg-[#BC6C25] pb-10">
        <h1 className="text-4xl pt-28 font-bold mb-4 font-raleway font-bold lg:text-5xl xl:text-6xl">
          {t('blog.mainTitle')}
        </h1>
        <p className="font-quicksand text-base lg:text-xl mb-8 px-4">
          {t('blog.description')}
        </p>

      </section>

      {/* Articles Section */}
      <section className="py-8 bg-[#606C38] px-6 md:px-12 lg:px-16">
        <div className=" mx-auto px-4 ">
          <h2 className="text-3xl font-bold mb-6 text-[#FEFAE0] font-raleway">{t('blog.subtitle')}</h2>
          <div className="text-[#283618] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {articles.map((article) => (

              <div className="bg-white h-50 p-4 border rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200 ">
                <h3 className="text-xl font-bold mb-2 font-raleway">{article.title}</h3>
                <p className="mb-2 font-quicksand">{article.excerpt}<Link key={article.id} to={`/blog/${article.slug}`}><strong> {t('blog.seeMore')}</strong> </Link></p>
                <span className="font-semibold text-sm font-quicksand">
                  {article.date}
                </span>
              </div>

            ))}

          </div>
        </div>
      </section>
    </>
  );
};

export default BlogPage;
