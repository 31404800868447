import React from 'react';
import Puente from "../assets/puente.jpeg";
import { useTranslation } from 'react-i18next';

const Hero = () => {
  const { t, i18n } = useTranslation();
  const phoneNumber = '+543794222281';
  const message = t('wabutton');

  return (
    <div className="relative w-full h-screen  " id='home'>


      <div className="area">
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>

      <div className="absolute inset-0 flex flex-col justify-center items-center  text-center">
        <div className='mx-10 pt-10 space-y-8 text-[#FEFAE0]'>
          <h1 className="text-3xl font-raleway font-bold md:text-4xl lg:text-5xl xl:text-6xl">{t('hero.welcome')}</h1>
          <h4 className='text-base lg:text-xl font-quicksand'> {t('hero.description')}<br />{t('hero.more')}</h4>
          <button
            aria-label={t('aria.contact')}
            className="font-quicksand shadow bg-[#BC6C25] hover:bg-[#DDA15E] text-[#FEFAE0] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:text-[#283618]">

            <a
              href={`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`}
              target="_blank"
              rel="noopener noreferrer"

            >{t('hero.button')}</a>
          </button>
        </div>
        <div className="w-full h-8 absolute -bottom-3 md:bottom-1 lg:bottom-3">
          <svg viewBox="0 0 1440 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 43.9999C106.667 43.9999 213.333 7.99994 320 7.99994C426.667 7.99994 533.333 43.9999 640 43.9999C746.667 43.9999 853.333 7.99994 960 7.99994C1066.67 7.99994 1173.33 43.9999 1280 43.9999C1386.67 43.9999 1440 19.0266 1440 9.01329V100H0V43.9999Z"
              className="fill-current text-[#FEFAE0]"></path>
          </svg>
        </div>
      </div>

    </div>
  );
}

export default Hero;
