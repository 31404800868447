import Argentina from "../assets/arg-flag.png";
import Usa from "../assets/us-flag.png";
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageToggle = () => {
  const { i18n } = useTranslation();
  const [isEnglish, setIsEnglish] = useState(i18n.language === 'en');

  const toggleLanguage = () => {
    const newLanguage = isEnglish ? 'es' : 'en';
    i18n.changeLanguage(newLanguage);
    setIsEnglish(!isEnglish);
  };

  return (
    <div className="fixed z-50 flex justify-center items-center">
      <button
        onClick={toggleLanguage}
        className="h-6 w-6 rounded-full bg-white  flex justify-center items-center"
      >
        {isEnglish ? (
          <img src={Argentina} alt="Español" className="block w-6 h-6 rounded-full" />
        ) : (
          <img src={Usa} alt="Inglés" className="block w-6 h-6 rounded-full" />
        )}
      </button>
    </div>
  );
};

export default LanguageToggle;
