import React from 'react'

const VMVCard = ({ title, content, isList = false }) => {
  return (
    <div
    className="transform rounded-xl h-96 w-72 bg-gradient-to-b from-[#283618] to-[#606C38]  shadow-xl transition duration-300 hover:scale-105 text-center px-6 pt-6 space-y-1 cursor-pointer"
>
   
   
                <h3 className="text-[#FEFAE0] text-xl font-raleway font-bold  ">{title}</h3>
                {isList ? (
                    <ul className="text-[#FEFAE0]">
                        {content.map((item, index) => (
                            <li key={index} className='font-quicksand text-[#FEFAE0] text-left'>
                            <strong>{item.title}</strong>: {item.description}</li>
                        ))}
                    </ul>
                ) : (
                    <p className="font-quicksand text-[#FEFAE0] text-left">{content}</p>
                )}
  
</div>

  )
}

export default VMVCard