import {useEffect} from 'react';
import './App.css';
import Header from "./sections/Header"; // Navbar actual
import Footer from "./sections/Footer";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import BlogPage from './pages/BlogPage';
import BlogPostPage from './pages/BlogPostPage';
import NavbarBlog from './sections/NavbarBlog';
import FooterBlog from "./sections/FooterBlog"
// Otros componentes de la página principal
import Hero from "./sections/Hero";
import Technologies from "./sections/Technologies";
import Methodologie from "./sections/Methodologie";
import Services from "./sections/Services";
import About from "./sections/About";
import Projects from "./sections/Projects";
import VMVSection from "./sections/VMVSection"
import NewsletterForm from './components/NewsletterForm';
import FAQ from "./components/FAQ";
import Pricing from './components/Pricing';
import { useTranslation } from 'react-i18next';
import Prices from './components/Prices';

function ScrollToHash() {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.getElementById(hash.replace("#", ""));
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth" });
        }, 100); // Retraso para asegurar renderizado completo
      }
    }
  }, []);

  return null; // No necesita renderizar nada
}

function App() {
 
  const { i18n } = useTranslation();
  return (
    <Router>
      <Routes>
        {/* Ruta para la página de inicio */}
        <Route path="/" element={
          <>
            <Header /> {/* Navbar actual solo en la página principal */}
            <Hero />
            {/* <Technologies />  */}

            <Services />
            <About />
            <Prices/>
            <Methodologie />
            
            <Projects />
            {/* {i18n.language === 'es' && <NewsletterForm />} */}
            <VMVSection />

            <FAQ />
            <Footer />
          </>
        } />

        {/* Ruta para mostrar todas las entradas del blog */}
        <Route path="/blog" element={
          <>
            <NavbarBlog />
            <BlogPage />
            <FooterBlog />
          </>
        } />

        {/* Ruta para mostrar un post específico */}
        <Route path="/blog/:postId" element={
          <>
            <NavbarBlog />
            <BlogPostPage />
            <FooterBlog />
          </>
        } />

        <Route path="/prices" element={
          <>
          <Pricing/>
          </>
        } />
      </Routes>
    </Router>
  );
}

export default App;
