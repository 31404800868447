import React from 'react'
import VMVCard from '../components/VMVCard'
import { useTranslation } from 'react-i18next';
import { FaRegHandshake } from 'react-icons/fa';
import { MdPublic } from 'react-icons/md';
import { GiAchievement } from 'react-icons/gi';



const VMVSection = () => {
    const { t } = useTranslation();
    const listItems = [
        { title: t('MSV.values.subtitle1'), description: t('MSV.values.description1') },
        { title: t('MSV.values.subtitle2'), description: t('MSV.values.description2') },
        // { title: t('MSV.values.subtitle3'), description: t('MSV.values.description3') },
        { title: t('MSV.values.subtitle4'), description: t('MSV.values.description4') },
        // { title: t('MSV.values.subtitle5'), description: t('MSV.values.description5') }
        
    ];


  return (
    <div className='flex gap-8 flex-wrap justify-center bg-[#FEFAE0] p-10'>
        <h2 className='text-2xl font-raleway font-bold my-8 md:text-4xl lg:text-5xl xl:text-6xl text-[#283618]'>{t('MSV.mainTitle')}</h2>
        <VMVCard
              
                title={t('MSV.mission.title')}
                content={t('MSV.mission.description')}
            />
            <VMVCard
                title={t('MSV.vision.title')}
                content={t('MSV.vision.description')}
            />
            <VMVCard
              
                title={t('MSV.values.title')}
                content={listItems}
                isList={true} 
            />
  
   </div>
  )
}

export default VMVSection