import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import ScrollToTopButton from './ScrollToTopButton';

const WhatsAppButton = () => {
  const { t } = useTranslation();
  const phoneNumber = '+543794222281'; 
  const message = t('wabutton'); 

  return (
    <div >
    <ScrollToTopButton />
   
    <a
      href={`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`}
      target="_blank"
      rel="noopener noreferrer"
      className="fixed bottom-8 right-7 p-3 bg-green-500 text-white rounded-full shadow-lg hover:bg-green-600 transition z-[500] "
    >
      <FaWhatsapp size={24} />
    </a>
    </div>
  );
};

export default WhatsAppButton;
