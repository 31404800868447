import React from 'react';
import { FaLinkedin, FaInstagram } from "react-icons/fa";
import { useTranslation } from 'react-i18next';
import WhatsAppButton from '../components/WhatsappButton';


const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer id='footer' className="flex flex-col space-y-10 justify-center py-20  px-10 bg-[#BC6C25]">
      <nav className="font-raleway flex justify-center flex-wrap gap-6 text-[#FEFAE0]">
        <a className="hover:text-[#283618]" href="#">{t('footer.home')}</a>
        {/* <a className="hover:text-[#283618]" href="#techs">{t('footer.tech')}</a>
        <a className="hover:text-[#283618]" href="#method">{t('footer.method')}</a> 
        <a className="hover:text-[#283618]" href="#projects">{t('footer.projects')}</a>*/}
        <a className="hover:text-[#283618]" href="#about">{t('footer.about')}</a>
        <a className="hover:text-[#283618]" href="#faq">FAQ</a>
        <a className="hover:text-[#283618]" href="#contact">{t('footer.contact')}</a>
        <a className="hover:text-[#283618]" href="/blog">Blog</a>

      </nav>

      <div className="flex justify-center space-x-5">
        <a href="https://www.linkedin.com/company/mburutech/" target="_blank" rel="noopener noreferrer" className='w-10 h-10 text-[#FEFAE0]'>
          <FaLinkedin className='w-full h-full'/>
        </a>

        <a href="https://instagram.com/mburutech" target="_blank" rel="noopener noreferrer" className='w-10 h-10 text-[#FEFAE0]'>
          <FaInstagram className='w-full h-full'/>
        </a>

      </div>
      <div  className="text-center text-[#FEFAE0] ">
     
      <p className='font-quicksand'>info@mburu.tech</p> 
      <p className='font-quicksand'>(+54)3794222281</p> 
      <WhatsAppButton/>
      </div>

  
    </footer>
  );
};

export default Footer;
