// StormEffect.js
import React, { useRef, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { Vector3 } from 'three';

const Star = ({ position }) => {
  const mesh = useRef();

  useFrame(() => {
    mesh.current.position.z += 0.1;
    if (mesh.current.position.z > 10) {
      mesh.current.position.z = -10;
    }
  });

  return (
    <mesh ref={mesh} position={position}>
      <sphereGeometry args={[0.02, 8, 8]} />
      <meshStandardMaterial emissive="#ffffff" emissiveIntensity={1} color="#ffffff" />
    </mesh>
  );
};

const Scene = () => {
  const stars = useRef([]);

  useEffect(() => {
    stars.current = Array.from({ length: 500 }, () => [
      Math.random() * 20 - 10,
      Math.random() * 20 - 10,
      Math.random() * 20 - 10,
    ]);
  }, []);

  return (
    <>
      <ambientLight intensity={0.2} />
      <pointLight position={[0, 5, 10]} intensity={1} color="#ffffff" />
      {stars.current.map((pos, index) => (
        <Star key={index} position={new Vector3(...pos)} />
      ))}
    </>
  );
};

const StormEffect = () => (
  <Canvas
    style={{ position: 'absolute', top: 0, left: 0, zIndex: -1 }}
    camera={{ position: [0, 0, 5], fov: 75 }}
  >
    <Scene />
    <color attach="background" args={['#000000']} />
  </Canvas>
);

export default StormEffect;
