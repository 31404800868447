import React from 'react';
import Tere from "../assets/tere.jpeg"
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation();
  const phoneNumber = '+543794222281'; 
  const message = t('wabutton'); 
  return (
    <div className="flex flex-wrap bg-[#FEFAE0] pb-8 md:pb-0" id="about">
       <img
        src={Tere}
        alt="Foto de perfil de Rosario"
        className="w-full h-full object-cover md:w-1/3"
      />
      <div className="w-full sm:w-8/12 ">
        <div className="container mx-auto h-full sm:p-10">
        
          <header className="container px-4 lg:flex mt-10 items-center h-full lg:mt-0">
            <div className="w-full">
              <h1 className="text-4xl font-raleway lg:text-5xl xl:text-6xl font-bold text-[#BC6C25]">
              {t('about.title')} <span className="text-[#606C38]">Mburu</span> Tech
              </h1>
              <div className="w-20 h-2 bg-[#606C38] my-4"></div>
              <p className="font-quicksand text-lg mb-10 text-[#283618] md:text-xl">
              {t('about.description')}
              </p>
              <button
              aria-label={t('aria.contact')}
               className="font-quicksand shadow bg-[#283618] hover:bg-[#606C38] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
           
             <a
      href={`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`}
      target="_blank"
      rel="noopener noreferrer"
    >{t('about.button')}</a>
          </button>
            </div>
          </header>
        </div>
      </div>
     
    </div>
  );
};

export default About;
